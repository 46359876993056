import React from 'react'
import Layout from '../templates/layout'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import ContactBox from '../components/ContactBox'
import '../styles/html-text.scss'

type Props = {
	data: {
		prismicTeamMembers: {
			data: {
				name: string
				image: {
					url: string
				}
				biography: {
					html: string
				}
			}
		}
	}
}
const TeamDetail = (data: Props) => {
	const teamMember = data.data.prismicTeamMembers.data
	return (
		<Layout border={true}>
			<div className='bg-cream relative lg:mb-[15.125rem] md:mb-40 mb-20'>
				<div className='container font-poppins'>
					<div className='lg:pt-[6.688rem] pt-10 pb-[6.688rem]'>
						<div className='mb-4'>
							<Link to='/' className='mr-6 text-small text-grey'>
								Ana Sayfa
							</Link>
							<Link to='/ekibimiz' className='font-medium text-small text-grey'>
								Ekibimiz
							</Link>
						</div>
						<h1 className='text-header font-medium'>{teamMember.name}</h1>
					</div>
					<img
						src={teamMember.image.url}
						alt=''
						className='absolute lg:left-1/2 left-5 lg:right-1/2 lg:-translate-x-1/2 lg:top-20 top-32 lg:h-[23.5rem] md:h-56 h-40'
					/>
				</div>
			</div>
			<div className='container mb-6'>
				<div className='lg:w-8/12 mx-auto font-merriweather leading-[34px] lg:mb-40 mb-20'>
					<div
						className='html-text'
						dangerouslySetInnerHTML={{ __html: teamMember.biography.html }}
					/>
				</div>
				<ContactBox />
			</div>
		</Layout>
	)
}

export const query = graphql`
	query TeamMember($uid: String) {
		prismicTeamMembers(uid: { eq: $uid }) {
			data {
				name
				image {
					url
				}
				biography {
					html
				}
			}
		}
	}
`
export default TeamDetail
